@import 'static/styles/theme';

.root {
	padding: 0 0 5px;
	border-radius: 10px;
	width: 400px;
	&::before {
		z-index: 2;
		border-bottom-color: #d2efea;
	}
}

.comment_root {
	width: 460px;
}

.bold {
	color: $colorError;
	font-weight: 500;
}

.icon {
	width: 24px;
	flex: 0 0 24px;
	color: $colorLight;
	cursor: pointer;
	&__active {
		color: $colorPrimary1;
	}
}

.notifications {
	position: relative;
	font-size: 0;
	&__badge {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		height: 17px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $colorError;
		padding: 0 6px;
		position: absolute;
		right: 2px;
		top: 2px;
		border-radius: 100%;
		color: white;
	}
}

.head {
	background: #d2efea;
	border-radius: 10px 10px 0px 0px;
	height: 40px;
	color: $colorPrimary2;
	padding: 0 10px 0 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 500;
	font-size: 14px;
	&__count {
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		height: 22px;
		min-width: 22px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 2px 7px;
		border: 1px solid $colorPrimary2;
		border-radius: 100%;
	}
}

.notification {
	display: flex;
	align-items: center;
}

.items {
	padding: 0 15px;
	max-height: 360px;
	overflow: auto;
	box-sizing: border-box;
}

.notification {
	font-weight: 300;
	font-size: 14px;
	line-height: 20px;
	border-bottom: 1px solid $colorGlow;
	padding: 10px 0;
	&:last-child {
		border-bottom: 0;
		padding-bottom: 5px;
	}
}

.withDebt {
	border: none;
}

.notificationLink {
	margin-top: 2px;
	padding-left: 5px;
	a {
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		color: $colorPrimary2;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		text-decoration: none;
	}
}

.notificationComment {
	display: flex;
	align-items: center;
	width: 100%;
	white-space: pre;

	strong {
		background: rgba(204, 236, 230, 1);
		color: $colorPrimary2;
		border-radius: 4px;
		background: rgb(204, 236, 230);
		padding: 0 5px;
		font-size: 12px;
	}
}

.notificationIcon {
	background: rgba(0, 160, 130, 0.15);
}

.notificationWrap {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.notificationLink {
	padding: 0;
}

.nowrap {
	white-space: nowrap;
}

.warn {
	color: $colorError;
	font-weight: 500;
}

.iconClose {
	flex: 0 0 12px;
	align-self: flex-start;
	cursor: pointer;
	color: $colorGrey;
	margin-left: 11px;
}

.accountingType {
	margin-right: 10px;
}

.warning {
	display: flex;
	align-items: center;
	white-space: nowrap;
	padding: 7px 10px;
	margin-top: 10px;
	&:first-child {
		margin-top: 0;
	}
	a {
		color: $colorPrimary2;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 12px;
		&:hover {
			color: $colorPrimary2Hover;
		}
	}
	svg {
		color: $colorError;
		width: 24px;
		flex: 0 0 24px;
		margin-right: 10px;
	}
}

.debtIcon {
	background-color: #fff5da;
	svg {
		color: $colorError;
	}
}

.debtList {
	width: 100%;
	padding-right: 20px;
	.debtRow {
		&:not(:first-child) {
			margin-bottom: 8px;
			div:last-child {
				color: $colorError;
			}
		}
	}
}

.debtRow {
	padding-left: 5px;
	display: flex;
	justify-content: space-between;
	font-family: Montserrat;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	text-align: left;
	margin-bottom: 6px;
	div:last-child {
		font-weight: 500;
	}
}

.expiredDebt30 {
	background-color: #fffbed;
	padding: 8px 15px;
	border-radius: 10px;
	margin-bottom: 5px;
}
