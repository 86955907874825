@import 'static/styles/theme';

.button {
	width: 60px;
	height: 60px;
	border-radius: 100%;
	border: none;
	cursor: pointer;
	background-color: $colorPrimary2;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	right: 30px;
	bottom: 30px;
	z-index: 3;
	svg {
		flex: 0 0 24px;
		color: #ffffff;
	}
}

.text {
	padding: 10px 20px;
	background-color: $colorSecondary1;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	margin-bottom: 20px;
}

.hint {
	font-size: 12px;
	line-height: 15px;
	color: $colorLight;
	text-align: left;
	padding-left: 20px;
	margin-top: 5px;
}
