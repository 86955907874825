@import 'static/styles/_theme.scss';
@import 'static/styles/_mixins.scss';
@include flex;

.root {
}

.text {
	font-size: 14px;
	margin-left: 10px;
}

.icon {
	color: $colorPrimary2;
}

.isOnline {
	.iconWrap {
		width: 40px;
		height: 40px;
		flex: 0 0 40px;
		background: $colorSecondary2;
		border-radius: 100%;
	}

	.text {
		color: $colorPrimary2;
	}
}

.isCurrent {
	.text {
		font-size: 12px;
		font-weight: 500;
	}
}
