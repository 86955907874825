@import 'static/styles/_theme.scss';
@import 'static/styles/_toast.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

body {
	margin: 0;
	color: $colorDark;
	position: relative;
	min-width: 1280px;
}

body,
input,
textarea,
button {
	font-family: 'Montserrat', sans-serif;
}

.hidden {
	overflow: hidden;
}
