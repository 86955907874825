$colorPrimary1: #f2cc38;
$colorPrimary2: #00a082;
$colorPrimary2Hover: #00b794;
$colorSecondary1: #fff9ed;
$colorSecondary2: #ccece6;
$colorError: #f65d3b;
$colorDark: #493f58;
$colorLight: #9393a1;
$colorLighter: #e2e2e6;
$colorPrimaryBG: #f2f3f5;
$colorSecondaryBG: #ffffff;
$colorLightBG: #f9f9fa;
$colorGrey: #797a8d;
$colorGlow: #d8d9e4;

$shadow: -4px 4px 14px rgba(78, 69, 92, 0.15);
$shadowSoft: -2px 2px 8px rgba(78, 69, 92, 0.08);

$sideBarWidth: 64px;
$openSideBarWidth: 170px;
$heightHeader: 60px;
