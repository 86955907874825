@import 'static/styles/theme';

.root {
	height: $heightHeader;
	background: $colorSecondaryBG;
	display: flex;
	align-items: center;
	justify-content: space-between;
	filter: drop-shadow($shadow);
	position: sticky;
	z-index: 3;
	top: 0;
	padding: 0 30px 0 26px;
	&::before {
		content: '';
		position: absolute;
		right: 100%;
		height: 100%;
		top: 0;
		background: $colorSecondaryBG;
		width: 100%;
	}
}

.logo {
	color: $colorPrimary2;
	margin-top: -8px;
}

.userInfo {
	display: flex;
	align-items: center;

	.info_header {
		display: flex;
		align-items: center;
		text-transform: capitalize;
		margin-right: 22px;
		font-weight: 500;
		gap: 7.67px;
		font-size: 14px;
		cursor: pointer;
		width: max-content;

		span {
			width: max-content;
		}

		&:not(:disabled):hover {
			background-color: transparent;
		}
	}

	a {
		text-decoration: none;
		span {
			text-transform: none;
		}
	}
}

.icon {
	width: 24px;
	flex: 0 0 24px;
	color: $colorLight;
	cursor: pointer;
}

.userInfoCell {
	margin-left: 20px;
	font-size: 0;
	&:first-child {
		margin-left: 0;
	}
}

.notifications {
	position: relative;
	font-size: 0;
	&::after {
		content: '';
		width: 6px;
		height: 6px;
		background: $colorError;
		position: absolute;
		right: 8px;
		top: 8px;
		border-radius: 100%;
	}
}

.logoutIcon {
	margin-left: 5px;
}

.warning {
	display: flex;
	align-items: center;
	white-space: nowrap;
	padding: 7px 10px;
	a {
		color: $colorPrimary2;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 12px;
		&:hover {
			color: $colorPrimary2Hover;
		}
	}
	svg {
		color: $colorError;
		width: 24px;
		flex: 0 0 24px;
		margin-right: 10px;
	}
}

.countrySwitcher {
	margin-right: 14px;
}
