@import 'static/styles/_theme.scss';

.root {
	margin-right: 14px;
	width: 40px;
	flex: 0 0 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	&__onceAWeek {
		background: #cbe6ff;
		color: #4999e3;
	}
	&__twiceAMonth {
		background: #ccece6;
		color: $colorPrimary2;
	}
	&__onceAMonth {
		background: #fff3da;
		color: $colorPrimary1;
	}
	&__docs {
		background: #fff5da;
		color: $colorError;
	}
}
