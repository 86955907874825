@import 'static/styles/theme';
@import 'static/styles/_mixins';

@include flex;

$z-index: 100;

.root {
	position: fixed;
	z-index: $z-index;
	right: 0;
	bottom: 0;
	top: 0;
	left: 0;
}

.container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.modal {
	position: relative;
	background: #ffffff;
	border-radius: 20px;
	box-shadow: $shadow;
	z-index: $z-index + 2;
	overflow: auto;
	margin: 20px;
	max-height: calc(100% - 40px);
	max-width: 930px;
	width: 100%;
	box-sizing: border-box;
}

.body {
	padding: 15px 30px 30px;
}

.overlay {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	left: 0;
	z-index: $z-index + 1;
	background: rgba($colorDark, 0.2);
}

.title {
	font-size: 18px;
	font-weight: 700;
	color: $colorDark;
	box-shadow: $shadowSoft;
	padding: 20px 30px;
	&_center {
		text-align: center;
	}
	&_withoutShadow {
		box-shadow: none;
	}
}

.closeButton {
	position: absolute;
	right: 20px;
	top: 20px;
	cursor: pointer;
	z-index: 1;
	display: flex;
	&:hover {
		svg {
			position: relative;
		}

		&::before {
			content: '';
			width: 35px;
			height: 35px;
			background-color: $colorGrey;
			position: absolute;
			opacity: 0.05;
			top: 50%;
			left: 50%;
			border-radius: 100%;
			transform: translate(-50%, -50%);
		}
	}
}

.iconClose {
	width: 16px;
	color: $colorLight;
}

.customPosition {
	.container {
		display: block;
	}
}
.buttons {
	margin-top: 30px;
	button {
		padding: 0;
		margin: 0;
		outline: none;
		border: none;
		background: none;
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
		cursor: pointer;
		color: $colorPrimary2;
		display: flex;
		align-items: center;

		&:disabled {
			color: $colorLight;
			cursor: auto;
		}
	}

	.buttonCancel {
		color: $colorLight;
	}
}
