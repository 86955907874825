@import 'static/styles/_theme.scss';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba($colorDark, 0.2);
	z-index: 1000;
}

.root {
	display: flex;
	align-items: center;
	justify-content: center;
}
