@import 'static/styles/theme';
@import 'static/styles/_mixins';

@include flex;

.modal {
	max-width: 500px;
	padding-top: 20px;

	&_centered {
		padding-top: 50px;
	}
}

.title {
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 26px;
	text-align: center;
}

.body {
	font-size: 14px;
	text-align: center;
	display: flex;
	justify-content: center;
}

.icon {
	width: 80px;
	height: 80px;
	background: $colorSecondary2;
	border-radius: 100%;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 30px;

	svg {
		width: 40px;
		color: $colorPrimary2;
	}

	&_copy {
		background: #cbe6ff;

		svg {
			height: 40px;
			color: #4999e3;
		}
	}

	&_edit {
		background: #cbe6ff;

		svg {
			height: 40px;
			margin-left: 6px;
			color: #4999e3;
		}
	}

	&_warning {
		background: #fff5da;

		svg {
			height: 40px;
			margin-bottom: 6px;
		}
	}
}
