.Toastify__toast {
	.Toastify__close-button {
		opacity: 0.7;
	}
}

.Toastify__toast--error {
	color: var(--toastify-color-light);
	background-color: var(--toastify-color-error);

	.Toastify__close-button > svg {
		fill: var(--toastify-color-light);
	}
}
.Toastify__toast--success {
	color: var(--toastify-color-light);
	background-color: var(--toastify-color-success);

	.Toastify__close-button > svg {
		fill: var(--toastify-color-light);
	}
}
.Toastify__toast--info {
	color: var(--toastify-color-light);
	background-color: var(--toastify-color-info);

	.Toastify__close-button > svg {
		fill: var(--toastify-color-light);
	}
}
.Toastify__toast--warning {
	color: var(--toastify-color-light);
	background-color: var(--toastify-color-warning);

	.Toastify__close-button > svg {
		fill: var(--toastify-color-light);
	}
}
