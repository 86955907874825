@import 'static/styles/_theme.scss';

.root {
	display: flex;
	justify-content: space-between;
	background: $colorPrimaryBG;
}

.body {
	min-height: 100vh;
	width: calc(100% - $sideBarWidth);
	&_withMenu {
		width: calc(100% - $openSideBarWidth);
	}
}
