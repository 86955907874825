@import 'static/styles/theme';
@import 'static/styles/_mixins';

$paddingLeft: 20px;

@mixin hover {
	color: $colorPrimary2;

	.icon {
		color: $colorPrimary2;
	}
}

.root {
	direction: rtl;
	width: $sideBarWidth;
	flex: 0 0 $sideBarWidth;
	height: 100vh;
	overflow: auto;
	background: $colorPrimary1;
	border-radius: 0 30px 30px 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	z-index: 5;
	position: sticky;
	top: 0;

	&_opened {
		width: $openSideBarWidth;
		flex: 0 0 $openSideBarWidth;

		.nav a {
			padding-right: 10px;
		}

		.active {
			background-color: #f9f9fa;
			border-radius: 20px;

			.icon {
				color: $colorPrimary2;
			}
		}

		.nav {
			.text {
				&:not(.active):hover {
					background-color: rgba(255, 255, 255, 0.3);

					@include hover();

					.icon {
						background-color: transparent;
					}
				}
			}
		}
	}

	@include dropdown-scroll(rgba(73, 63, 88, 0.3));

	&::-webkit-scrollbar {
		width: 2px;
	}
}

.icon {
	width: 24px;
	flex: 0 0 24px;
	color: $colorDark;
}

.text {
	font-size: 12px;
	color: $colorDark;
	text-decoration: none;
	font-weight: 500;
}

.openMenu {
	direction: initial;
	height: $heightHeader;
	flex: 0 0 $heightHeader;
	display: flex;
	align-items: center;
	padding-left: $paddingLeft;
	position: sticky;
	top: 0;
	z-index: 2;
	background-color: $colorPrimary1;

	.icon {
		margin-right: 13px;
	}

	.text {
		cursor: pointer;
		display: flex;
		align-items: center;

		&:hover {
			@include hover();
		}
	}
}

.nav {
	margin: 15px 0 auto;
	direction: initial;

	.text {
		display: flex;
		align-items: center;
		cursor: pointer;
		border-radius: 20px;
		justify-content: flex-start;

		div {
			z-index: 1;
		}

		.icon {
			z-index: 1;
			padding: 7.5px 12px;
			min-height: 40px;
			min-width: 48px;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			display: flex;
			border-radius: 20px;
			&:hover {
				background-color: rgba(255, 255, 255, 0.3);
				@include hover();
			}
		}

		&.active {
			position: relative;

			.icon {
				color: $colorPrimary2;
				background-color: #f9f9fa;
			}
		}
	}
}

.group {
	border-bottom: 1px solid rgba(73, 63, 88, 0.2);
	margin: 0 10px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	padding: 5px 0;

	&:last-child {
		border-bottom: none;
	}
}
