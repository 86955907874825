@import './theme';

@mixin mainStatuses {
	.success {
		color: $colorPrimary2;
	}

	.error {
		color: $colorError;
	}

	.dark {
		color: $colorDark;
	}
}

@mixin marginWithoutFirstChild($margin, $property: top) {
	margin-#{$property}: #{$margin}px;
	&:first-child {
		margin-#{$property}: 0;
	}
}

@mixin flex {
	.flex {
		display: flex;
	}

	.alignItemsCenter {
		align-items: center;
	}

	.alignItemsStart {
		align-items: flex-start;
	}

	.alignItemsEnd {
		align-items: flex-end;
	}

	.justifyContentBetween {
		justify-content: space-between;
	}

	.justifyContentStart {
		justify-content: flex-start;
	}

	.justifyContentEnd {
		justify-content: flex-end;
	}

	.justifyContentCenter {
		justify-content: center;
	}
}

@mixin placement($offset: 5px, $borderColor: #ffffff) {
	position: absolute;
	&::before {
		content: '';
		display: block;
		border: 6px solid transparent;
		position: absolute;
	}

	&_left {
		right: 100%;
		top: 50%;
		transform: translateY(-50%);
		margin-right: $offset;
		&::before {
			border-left-color: $borderColor;
			top: 50%;
			transform: translateY(-50%);
			left: 100%;
		}
	}
	&_left-end {
		right: 100%;
		bottom: 0;
		margin-right: $offset;
	}
	&_left-start {
		right: 100%;
		top: 0;
		margin-right: $offset;
	}
	&_right {
		left: 100%;
		top: 50%;
		transform: translateY(-50%);
		margin-left: $offset;
		&::before {
			border-right-color: $borderColor;
			top: 50%;
			transform: translateY(-50%);
			right: 100%;
		}
	}
	&_right-start {
		left: 100%;
		top: 0;
		margin-left: $offset;
	}
	&_right-end {
		left: 100%;
		bottom: 0;
		margin-left: $offset;
	}
	&_bottom {
		left: 50%;
		top: 100%;
		transform: translateX(-50%);
		margin-top: $offset;
		&::before {
			border-bottom-color: $borderColor;
			bottom: 100%;
			transform: translateX(-50%);
			left: 50%;
		}
	}
	&_bottom-start {
		left: 0;
		top: 100%;
		margin-top: $offset;
	}
	&_bottom-end {
		right: 0;
		top: 100%;
		margin-top: $offset;
	}
	&_top {
		left: 50%;
		bottom: 100%;
		transform: translateX(-50%);
		margin-bottom: $offset;
	}
	&_top-start {
		left: 0;
		bottom: 100%;
		margin-bottom: $offset;
	}
	&_top-end {
		right: 0;
		bottom: 100%;
		margin-bottom: $offset;
	}
}

@mixin copy-hover($right: 6px) {
	opacity: 0;
	position: absolute;
	transition: all 0.5s ease-in-out;
	top: 50%;
	right: $right;
	transform: translate(0, -50%);
	&:hover {
		opacity: 1;
		&::before {
			content: '';
			background: #3a35410a;
			width: 35px;
			height: 35px;
			position: absolute;
			top: 50%;
			right: 50%;
			transform: translate(50%, -55%);
			border-radius: 50%;
		}
	}
}

@mixin docStatus($textColor, $bgColor) {
	padding: 4px 6px;
	border-radius: 10px;
	background-color: $bgColor;
	color: $textColor;
	max-width: 90%;
	font-weight: 500;
}

@mixin dropdown-scroll($barColor) {
	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $barColor;
		border-radius: 5px;
	}
	&::-webkit-scrollbar-track-piece:start {
		background: transparent;
		margin-top: 12px;
	}
	&::-webkit-scrollbar-track-piece:end {
		background: transparent;
		margin-bottom: 12px;
	}
}

@mixin noAutofillBg {
	&:where(:autofill, :autofill:hover, :autofill:focus, :-webkit-autofill:hover, :-webkit-autofill:focus, :-internal-autofill-selected) {
		box-shadow: 0 0px 1000px var(--no-autofill-bg, $colorSecondaryBG) inset !important;
		-webkit-box-shadow: 0 0px 1000px var(--no-autofill-bg, $colorSecondaryBG) inset !important;
		transition: background-color 5000s ease-in-out 0s !important;
	}
}
