@import 'static/styles/_theme.scss';
@import 'static/styles/_mixins.scss';

.root,
.inner {
	position: relative;
}

.control {
	background: #ffffff;
	border: 1px solid $colorLighter;
	box-sizing: border-box;
	border-radius: 30px;
	padding: 0 20px;
	height: 40px;
	outline: none;
	color: $colorDark;
	width: 100%;
	font-size: 14px;
	transition: 0.3s border-color;

	&::placeholder,
	&_empty {
		color: $colorLight;
	}

	&:disabled {
		background: $colorPrimaryBG;
		pointer-events: none;
	}

	&:focus,
	&.focus {
		border-color: $colorLight;
	}

	&.error {
		border-color: $colorError;
	}
}

.disabled {
	.control {
		pointer-events: none;
		background: $colorPrimaryBG;
	}

	.icon {
		pointer-events: none;
		color: rgba($colorLight, 0.5);
	}
}

.controlSelect {
	padding-right: 54px;
	display: flex;
	align-items: center;
	cursor: pointer;

	span {
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.textField {
	@include noAutofillBg();
}

.icon {
	width: 24px;
	flex: 0 0 24px;
	color: $colorLight;
	position: absolute;
	top: 50%;
	left: 20px;
	transform: translateY(-50%);
	z-index: 1;

	&_color {
		&__success {
			color: $colorPrimary2;
		}
	}

	&_close {
		left: auto;
		right: 18px;
		width: 12px !important;
		height: 12px !important;
	}
}

.withIcon {
	padding-left: 49px;
}

.positionIconRight {
	.icon {
		left: auto;
		right: 20px;
		background: #fff;
	}

	.withIcon {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.disabled_icon {
	background: #f2f3f5 !important;
}

.selectArr {
	left: auto;
	right: 20px;
}

.rotateArr {
	transform: translate(0%, -50%) rotate(180deg);
}

.clearValue {
	width: 14px;
	flex: 0 0 14px;
	color: $colorLight;
	margin-right: 5px;
	cursor: pointer;
}

.withDatePicker {
	cursor: pointer;
}

.checkbox {
	display: flex;
	align-items: center;
	cursor: pointer;

	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}

.checkboxIcon {
	width: 18px;
	height: 18px;
	box-sizing: border-box;
	flex: 0 0 18px;
	border: 2px solid $colorLight;
	border-radius: 2px;
	margin-right: 10px;
	position: relative;

	&:hover::before {
		content: '';
		width: 35px;
		height: 35px;
		padding: 0px 3px;
		background-color: $colorGrey;
		position: absolute;
		opacity: 0.05;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 0;
		border-radius: 50%;
	}

	&_active {
		border: 0;

		svg {
			color: $colorPrimary2;
			width: 100%;
			flex: 0 0 100%;
		}
	}

	&_noActive {
		svg {
			margin-top: -2px;
			width: 100%;
			flex: 0 0 100%;

			path {
				fill: none;
			}
		}
	}
}

.checkboxText {
	font-size: 12px;
	color: $colorDark;
}

.clear {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.clearIcon {
	width: 22px;
	flex: 0 0 22px;
	color: $colorPrimary2;
	margin-right: 10px;
}

.clearText {
	font-size: 12px;
	color: $colorDark;
}

.dropDownSelect {
	position: absolute;
	top: 100%;
	margin-top: 2px;
	left: 50%;
	transform: translateX(-50%);
	background: #ffffff;
	box-shadow: $shadow;
	border-radius: 20px;
	min-width: 100%;
	z-index: 4;
	box-sizing: border-box;
	padding: 15px 0 0;
	max-height: 450px;
	overflow: auto;
	&.bottomGap {
		padding-bottom: 15px;
	}
	&.empty {
		padding: 0;
	}
	&_portal {
		top: 0;
		min-width: auto;
		z-index: 101;
	}
}

.selectSearchRoot {
	padding: 0 10px 5px;
}

.selectSearchControl {
	border-color: $colorGlow;
}

.selectOption {
	font-size: 14px;
	display: flex;
	align-items: center;
	padding: 10px 30px;
	cursor: pointer;

	&:hover,
	&_active {
		background-color: $colorPrimaryBG;
	}

	&_onlyView {
		pointer-events: none;
	}
}

.selectCheckboxRoot {
	width: 100%;
}

.selectCheckboxText {
	width: calc(100% - 28px);
	display: flex;
	align-items: center;
	justify-content: space-between;

	svg {
		width: 24px;
		flex: 0 0 24px;
		color: $colorLight;
	}
}

.buttons {
	position: sticky;
	bottom: 0;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px;

	button {
		font-size: 12px;
		background: none;
		border: none;
		outline: none;
		color: $colorPrimary2;
		text-transform: uppercase;
		cursor: pointer;
		font-weight: 500;
	}

	.buttonCancel {
		color: $colorLight;
	}
}

.input {
	position: relative;

	[class~='react-datepicker-wrapper'] {
		width: 100%;
	}

	[class~='react-datepicker-popper'] {
		padding-top: 0 !important;
		margin-top: 2px;
		z-index: 2;
	}

	[class~='react-datepicker__close-icon'] {
		&::after {
			visibility: hidden;
			background-color: transparent;
			color: $colorGrey;
			font-size: 20px;
		}
		&:disabled {
			display: none;
		}
	}

	&:hover {
		[class~='react-datepicker__close-icon'] {
			&::after {
				visibility: visible;
			}
		}
	}
}

.datepicker {
	display: flex !important;
	z-index: 2;
	border: none !important;
	background: #ffffff;
	box-shadow: $shadow;
	border-radius: 20px !important;
	padding: 30px !important;
	font-family: 'Montserrat' !important;

	[class~='react-datepicker__triangle'] {
		display: none;
	}

	[class~='react-datepicker__header'] {
		border: none;
		background: none;
		padding: 0;
	}

	[class~='react-datepicker__month-container'] {
		margin-right: 30px;

		&:last-child {
			margin-right: 0;
		}
	}

	[class~='react-datepicker__day-names'],
	[class~='react-datepicker__week'] {
		display: flex;
		align-items: center;
		margin-top: 10px;
	}

	[class~='react-datepicker__day'],
	[class~='react-datepicker__day-name'] {
		width: 34px;
		flex: 0 0 34px;
		height: 34px;
		align-items: center;
		justify-content: center;
		display: flex;
		margin: 0;
		padding: 0;
		color: $colorDark;
		position: relative;
		border-radius: 0;
		text-transform: capitalize;
		background: none;
		outline: 0;

		&:first-child {
			border-radius: 17px 0 0 17px;
		}

		&:last-child {
			border-radius: 0 17px 17px 0;
		}

		&:hover {
			background: none;
		}

		&:hover:not(:last-child):not(:first-child) {
			border-radius: 0;
		}
	}

	[class~='react-datepicker__day-name'],
	[class~='react-datepicker__day--disabled'] {
		color: $colorLight;
	}

	[class~='react-datepicker__month'] {
		margin: 0;
	}

	[class~='react-datepicker__day--in-selecting-range'],
	[class~='react-datepicker__day--in-range'] {
		background: $colorSecondary2 !important;
		border-radius: 0;
	}

	[class~='react-datepicker__day--outside-month'] {
		visibility: hidden;
	}

	[class~='react-datepicker__day--selected'] {
		border-top-left-radius: 17px !important;
		border-bottom-left-radius: 17px !important;
	}

	[class~='react-datepicker__day--range-end']:not([class~='react-datepicker__day--selected']),
	[class~='react-datepicker__day--selecting-range-end'] {
		border-top-right-radius: 17px !important;
		border-bottom-right-radius: 17px !important;
	}

	[class~='react-datepicker__day--selected'],
	[class~='react-datepicker__day--range-end'],
	[class~='react-datepicker__day--selecting-range-end'] {
		background-color: $colorSecondary2 !important;
		position: relative;
		z-index: 1;
		color: #ffffff !important;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 100%;
			background: $colorPrimary2;
			z-index: -1;
		}
	}

	[class~='react-datepicker__month'] [class~='react-datepicker__day--today']:not([class~='react-datepicker__day--outside-month']) {
		color: $colorDark !important;

		&::before {
			background-color: #fff !important;
			border: 1px solid $colorPrimary2;
			top: -1px;
			left: -1px;
		}
	}

	[class~='react-datepicker__current-month'] {
		text-align: center;
		padding-bottom: 30px;
		border-bottom: 1px solid #e2e2e6;
		position: relative;
		text-transform: capitalize;
		font-size: 14px;
		font-weight: 700;
		position: relative;
	}

	[class~='react-datepicker__current-month'],
	[class~='react-datepicker__month-read-view--selected-month'],
	[class~='react-datepicker__year-read-view'] {
		color: $colorDark;
		font-weight: 600;
		font-size: 14px;
	}

	[class~='react-datepicker__navigation--previous'] {
		left: 27px;
	}

	[class~='react-datepicker__navigation--next'] {
		right: 27px;
	}

	[class~='react-datepicker__navigation--previous'],
	[class~='react-datepicker__navigation--next'] {
		top: 25px;
		width: 28px;
		height: 28px;
		background: rgba(121, 122, 141, 0.05);
		border-radius: 14px;
	}

	[class~='react-datepicker__navigation--years'],
	[class~='react-datepicker__navigation--month'] {
		height: 13px;
		width: 13px;
		top: 0;
		&::before {
			border-width: 2px 2px 0 0;
			border-style: solid;
			width: 7px;
			height: 7px;
			border-color: #9393a1;
			content: '';
			position: absolute;
			left: 2px;
			top: 0px;
			transform: rotate(135deg);
		}
	}

	[class~='react-datepicker__navigation--years-upcoming'] {
		&::before {
			transform: rotate(-45deg);
			top: 4px;
		}
	}

	[class~='react-datepicker__navigation-icon'] {
		top: 1px;
	}

	[class~='react-datepicker__navigation-icon--previous'] {
		right: 1px;
	}

	[class~='react-datepicker__navigation-icon--next'] {
		left: 1px;
	}

	[class~='react-datepicker__navigation-icon']::before,
	[class~='react-datepicker__month-read-view--down-arrow'],
	[class~='react-datepicker__year-read-view--down-arrow'] {
		border-width: 2px 2px 0 0;
		width: 7px;
		height: 7px;
		border-color: $colorLight;
	}

	[class~='react-datepicker__month-read-view--down-arrow'],
	[class~='react-datepicker__year-read-view--down-arrow'] {
		top: 2px;
	}

	[class~='react-datepicker__header__dropdown'] {
		background: #fff;
		position: absolute;
		top: 0;
		right: 40px;
	}

	[class~='react-datepicker__year-read-view'],
	[class~='react-datepicker__month-read-view'] {
		visibility: visible !important;
	}

	[class~='react-datepicker__year-dropdown'],
	[class~='react-datepicker__month-dropdown'] {
		background: #fff;
		border-radius: 10px;
		border: none;
		font-weight: 400;
		font-size: 14px;
		width: 100px;
		z-index: 999;
		left: 0;
		top: 27px;
		box-shadow: -4px 4px 14px rgba(78, 69, 92, 0.15);
	}

	[class~='react-datepicker__year-dropdown'] {
		left: 50%;
	}

	[class~='react-datepicker__year-option'],
	[class~='react-datepicker__month-option'] {
		padding: 6px 0;

		&:hover {
			background-color: #f2f3f5;
			color: $colorPrimary2;
		}
	}

	[class~='react-datepicker__year-option--selected_year'],
	[class~='react-datepicker__month-option--selected_month'] {
		background-color: #f2f3f5;
		color: $colorPrimary2;
	}

	[class~='react-datepicker__year-option--selected'],
	[class~='react-datepicker__month-option--selected'] {
		visibility: hidden;
	}

	&.simple {
		[class~='react-datepicker__day--selected'] {
			border-radius: 100% !important;
		}
	}

	[class~='react-datepicker__month-read-view--selected-month'],
	[class~='react-datepicker__year-read-view--selected-year'] {
		font-weight: 600;
		font-size: 14px;
		text-transform: capitalize;
	}
}

.label {
	display: block;
	font-size: 12px;
	color: $colorLight;
	padding: 0 0 5px 20px;
	text-align: left;
}

.subLabel {
	font-size: 10px;
}

.file {
	color: $colorPrimary2;
	font-size: 12px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		cursor: pointer;
		width: 100%;
		height: 100%;

		&::before {
			content: '';
			display: inline-block;
			width: 100%;
		}
	}
}

.fileIcon {
	width: 15px;
	flex: 0 0 15px;
	margin-right: 10px;
}

.error {
	color: $colorError;
}

.fileInner {
	display: flex;
	align-items: center;
	max-width: calc(100% - 310px);
}

.filename {
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.info {
	display: flex;
	align-items: center;
	font-size: 12px;
	color: $colorLight;
	white-space: nowrap;

	svg {
		width: 24px;
		flex: 0 0 24px;
		margin-right: 10px;
	}
}

.fileLoaded {
	cursor: pointer;
	display: flex;
	align-items: center;
}

.fileLoadedIcon {
	width: 19px;
	flex: 0 0 19px;
	color: $colorPrimary2;
}

.fileLoadedName {
	font-size: 12px;
	color: $colorDark;
	text-decoration: underline;
	margin: 0 10px;
}

.fileLoadedSize {
	font-size: 12px;
	color: $colorLight;
}

.pointer {
	cursor: pointer;
}

.radio {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.radioIcon {
	width: 20px;
	height: 20px;
	box-sizing: border-box;
	flex: 0 0 20px;
	border: 2px solid $colorLight;
	border-radius: 100%;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;

	&_active {
		border-color: $colorPrimary2;

		&::before {
			width: 10px;
			height: 10px;
			flex: 0 0 10px;
			content: '';
			background: $colorPrimary2;
			border-radius: 100%;
		}
	}
}

.radioText {
	font-size: 12px;
	color: $colorDark;
}

.fullWidth {
	width: 100%;
}

.errorText {
	font-size: 12px;
	color: $colorError;
	margin-top: 5px;
	text-align: left;
	padding-left: 20px;
}

textarea.control {
	padding-top: 11px;
	height: 80px;
	resize: none;
	border-radius: 20px;
}

.uploadFile {
	display: flex;
	align-items: center;
	margin-top: 5px;

	&:first-child {
		margin-top: 0;
	}
}

.uploadFiles {
	max-width: 100%;
}

.fileRemove {
	padding-left: 10px;
	color: $colorGrey;

	svg {
		width: 12px;
		height: 12px;
	}
}

.fileSize {
	font-size: 12px;
	color: $colorGrey;
}

.errorLimit {
	svg,
	div {
		color: $colorError;
	}

	.fileRemove {
		svg {
			color: $colorGrey;
		}
	}
}

.exportIcon {
	color: $colorPrimary2;
	margin-right: 5px;
	min-width: 24px;
}
