@import 'static/styles/_theme.scss';
@import 'static/styles/_mixins.scss';

.root {
	position: relative;
	display: inline-block;
	max-width: 100%;
	&_withHover:hover {
		.tooltip {
			display: block;
		}
	}
}

.tooltip {
	position: absolute;
	background: #ffffff;
	border-radius: 4px;
	padding: 7px 8px 9px 11px;
	display: none;
	box-shadow: $shadow;
	z-index: 100;
	font-weight: 400;
	font-size: 14px;
	font-style: normal;
	color: $colorDark;
	$borderColor: #ffffff;
	text-align: left;
	&_warning {
		background-color: $colorSecondary1;
		$borderColor: $colorSecondary1;
	}
	@include placement(10px, $borderColor);
	&_open {
		display: block;
	}
}

.nowrap {
	white-space: nowrap;
}
